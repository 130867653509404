import React from 'react';
import { Typography } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from "@mui/material/styles";

import voltawareSensorIcon from '../../images/voltaware-sensor-icon.svg';
import voltawareDataIngestionDiagram from '../../images/voltaware-data-ingestion-diagram.svg';
import voltawareMetadataIngestionDiagram from '../../images/voltaware-metadata-ingestion-diagram.svg';
import requestingDisaggregationResultsDiagram from '../../images/requesting-disaggregation-results-diagram.svg';
import receivingRawDataDiagram from '../../images/receiving-raw-data-diagram.svg';

import Layout from '../../components/Layout';
import {
  Title,
  Button as CustomButton,
  Text as CustomText
} from '../../mui-elements/shared';
import { SEO } from '../../components/SEO';

const Button = styled(CustomButton)({
  maxWidth: 500,
  marginTop: '1rem',
  alignSelf: 'center',
}) as typeof CustomButton;

const Text = styled(CustomText)({
  textAlign: 'justify',
}) as typeof CustomText;

import {
  Header,
  Section,
  WhiteSection,
  GreySection,
  Diagram,
} from '../../style/pages/integrations';

export default function VoltawareSensors() {
  return (
    <Layout>
      <Header>
        <div>
          <img src={voltawareSensorIcon} alt="Voltaware Sensor icon" />
          <Typography component="h1" variant="h1">
            Voltaware Sensors
          </Typography>
        </div>
      </Header>
      <WhiteSection>
        <Section>
          <Title component="h2" variant="h2">
            How It Works
          </Title>

          <Text my={1}>
            Voltaware’s sensor captures real-time appliance-level electrical 
            data in the fuse box by non-invasive contact. It constantly samples 
            voltage and current signals of the main line at a high frequency.
          </Text>
          <Text my={1}>
            Every time an appliance is switched on or off, the sensor collects 
            different data points related to that particular event and this is 
            transmitted to the cloud server.
          </Text>
          <Text my={1}>
            Voltaware will collect this data to provide you with daily and 
            monthly disaggregation-based insights to integrate back into your 
            customer-facing applications.
          </Text>
        </Section>
      </WhiteSection>
      <GreySection>
        <Section>
          <Title component="h2" variant="h2">
            Data Ingestion
          </Title>
          <Diagram src={voltawareDataIngestionDiagram} />
          <Text my={1}>
            The Voltaware sensors are constantly transmitting data to our 
            backend so once you install the sensor and provision it to your 
            wifi you won’t need to follow any additional steps.
          </Text>
        </Section>
      </GreySection>
      <WhiteSection>
        <Section>
          <Title component="h2" variant="h2">
            Metadata Ingestion
          </Title>
          <Diagram src={voltawareMetadataIngestionDiagram} />
          <Text my={1}>
            In order to optimize our results’ accuracy, Voltaware needs to 
            receive metadata from your backend. This metadata includes users’ 
            information such as: 
          </Text>
          <ul>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Property type</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>House size</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Number of bedrooms</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Number of people</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Home type</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Whether the user generates power or not</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>Power Generation source</Text>
            </li>
            <li>
              <ArrowRightIcon fontSize="large" />
              <Text>List of appliances </Text>
            </li>
          </ul>
          <Text my={1}>
            Please send this information through our HTTP Services API.
          </Text>
          <Text my={1}>
            In addition, this information can also be sent to us directly via 
            the Voltaware Home app so it’s not necessary for you to connect to 
            our HTTP Services API.
          </Text>
          <Button
            fullWidth={true}
            variant="outlined"
            role="link"
            href="https://docs.voltaware.com/http-services.html#appliances"
            target="_blank"
          >
            Metadata Ingestion Documentation
          </Button>
        </Section>
      </WhiteSection>
      <GreySection>
        <Section>
          <Title component="h2" variant="h2">
            Requesting Disaggregation Results
          </Title>
          <Diagram src={requestingDisaggregationResultsDiagram} />
          <Text my={1}>
            To provide you with a list of appliances or appliance groups used 
            by each client, please request it using our HTTP Services API and 
            store them in your database. You can then use these results to 
            create your own mobile app, web app, smart bill or any other 
            customer facing product.
          </Text>
          <Button
            fullWidth={true}
            variant="outlined"
            role="link"
            href="https://docs.voltaware.com/http-services.html#disaggregation"
            target="_blank"
          >
            Disaggregation Results Documentation
          </Button>
        </Section>
      </GreySection>
      <WhiteSection>
        <Section>
          <Title component="h2" variant="h2">
            Receiving Sensor Raw Data
          </Title>
          <Diagram src={receivingRawDataDiagram} />
          <Text my={1}>
            To receive in real-time the Voltaware sensors’ data please 
            subscribe to our AMPQ Messaging Bus. In this queue, we will publish 
            each event sent by your sensors in real-time.
          </Text>
          <Button
            fullWidth={true}
            variant="outlined"
            role="link"
            href="https://docs.voltaware.com/amqp-sensor.html"
            target="_blank"
          >
            Sensor Raw Data Documentation
          </Button>
        </Section>
      </WhiteSection>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)
